<template>
  <v-app>
    <default-view />
  </v-app>
</template>

<script>
  export default {
    name: 'FrontLayout',
    components: {
      DefaultView: () => import('./View')
    },
  }
</script>
